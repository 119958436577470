"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberInput = void 0;
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var Ref_1 = require("../../Core/Ref");
var NumberInput = /** @class */ (function (_super) {
    __extends(NumberInput, _super);
    function NumberInput(ref) {
        if (ref === void 0) { ref = new Ref_1.Ref(0); }
        var _this = _super.call(this, "input", ref) || this;
        _this.min = undefined;
        _this.max = undefined;
        _this.Class(NumberInput.DefaultCssClasses);
        _this.element.setAttribute("type", "number");
        _this.element.setAttribute("value", _this.ref.value.toString());
        _this.element.addEventListener('input', function (e) {
            _this.ref.value = +e.target.value; // TODO: zwalidować czy to nie powoduje zapętlenia refreshy
        });
        _this.element.addEventListener('mousewheel', function (e) {
            _this.ref.value = +e.target.value;
        });
        return _this;
    }
    NumberInput.prototype.Step = function (step) {
        this.element.setAttribute("step", step.toString());
        return this;
    };
    NumberInput.prototype.Min = function (min) {
        this.min = min;
        this.element.setAttribute("min", min.toString());
        return this;
    };
    NumberInput.prototype.Max = function (max) {
        this.max = max;
        this.element.setAttribute("max", max.toString());
        return this;
    };
    NumberInput.prototype.MinRef = function (min) {
        var _this = this;
        this.min = min.value;
        this.element.setAttribute("min", min.value.toString());
        min.OnChange(function (v) {
            _this.min = v;
            _this.element.setAttribute("min", v.toString());
        });
        return this;
    };
    NumberInput.prototype.MaxRef = function (max) {
        var _this = this;
        this.max = max.value;
        this.element.setAttribute("max", max.value.toString());
        max.OnChange(function (v) {
            _this.max = v;
            _this.element.setAttribute("max", v.toString());
        });
        return this;
    };
    NumberInput.prototype.Range = function (min, max) {
        this.Min(min);
        this.Max(max);
        return this;
    };
    NumberInput.prototype.Refresh = function () {
        this.element.value = this.ref.value.toString();
        if (this.min !== undefined && this.max !== undefined) {
            if (this.min !== this.max && this.ref.value >= this.min && this.ref.value <= this.max)
                this.Style("background", "");
            else
                this.Style("background", "pink");
        }
        if (this.showThatChanged)
            this.ref.HasChanged
                ? this.element.classList.add("tf-value-changed")
                : this.element.classList.remove("tf-value-changed");
    };
    NumberInput.Name = "NumberInput";
    NumberInput.DefaultCssClasses = "";
    return NumberInput;
}(RefComponentBase_1.RefComponentBase));
exports.NumberInput = NumberInput;
