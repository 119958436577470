import { List } from "./List";
import { Song } from "./Song";


export class Playlist extends List<Song>
{
    public get Songs(): Song[]
    {
        return this.Items;
    }

    constructor(public Name: string, public Source: string)
    {
        super()
    }

    public LoadSongs(songsNamesWithExtension: string[]): this
    {
        songsNamesWithExtension.forEach(sn =>
        {
            const song = new Song();
            song.Name = this.RemoveExtension(sn);
            song.Type = this.GetExtension(sn);
            song.Url = `${this.Source}/${sn}`;
            this.Songs.push(song);
        });

        this.Init(this.Songs)

        return this;
    }

    private RemoveExtension(fileNameWithExtension: string): string
    {
        return fileNameWithExtension.split('/').pop().split('.').slice(0, -1).join('.');
    }

    private GetExtension(fileNameWithExtension: string): string
    {
        return fileNameWithExtension.split('.').pop() || "";
    }
}
