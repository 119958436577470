import { Div } from "@tblabs/truffle";
import { Playlist } from "../Models/Playlist";
import { Song } from "../Models/Song";
import { PlaylistEntryView } from "./PlaylistEntryView";


export class PlaylistViewer extends Div
{
    private marked?: PlaylistEntryView;
    private lastMarked?: PlaylistEntryView;
    private entries: PlaylistEntryView[] = [];

    constructor()
    {
        super();
        this.Padding(8).Background("#2f2f2f")
    }

    public Mark(song: Song): this
    {
        this.lastMarked?.Unselected()

        if (this.lastMarked?.song.IsBroken)
        {
            this.lastMarked.Broken()
        }

        this.marked = this.entries.find(x => x.song == song);

        this.marked?.Selected()

        this.lastMarked = this.marked;

        return this;
    }

    public MarkAsInvalid(song: Song)
    {
        if (song.IsBroken == false)
        {
            const entry = this.entries.find(x => x.song == song);
            entry?.Broken()
            song.IsBroken = true;
        }
    }

    public Load(playlist: Playlist)
    {
        this.entries = [];

        playlist.Songs.forEach(s =>
        {
            const entryView = new PlaylistEntryView(s);
            entryView.OnClick(() => this.onSongClick(s));

            this.entries.push(entryView);
        });
        // console.log(this.entries)

        this.Clear()
            .Append(...this.entries);

        return this;
    }

    private onSongClick;
    public OnSongClick(handler: (song: any) => void)
    {
        this.onSongClick = handler;
        return this;
    }
}
