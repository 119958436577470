"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumericRange = void 0;
var __1 = require("../..");
var NumericRange = /** @class */ (function (_super) {
    __extends(NumericRange, _super);
    function NumericRange(min, max, range) {
        var _this = _super.call(this) || this;
        _this.min = min;
        _this.max = max;
        _this.minInput = new __1.NumberInput(_this.min).HalfWidth();
        _this.maxInput = new __1.NumberInput(_this.max).HalfWidth();
        if (range) {
            _this.minInput.Min(range[0]);
            _this.maxInput.Max(range[1]);
        }
        _this.minInput.MaxRef(max);
        _this.maxInput.MinRef(min);
        _this.Append(_this.minInput)
            .Append(_this.maxInput);
        return _this;
    }
    NumericRange.prototype.ShowThatChanged = function (showOrNot) {
        this.minInput.ShowThatChanged(showOrNot);
        this.maxInput.ShowThatChanged(showOrNot);
        return this;
    };
    NumericRange.Name = "NumericRange";
    return NumericRange;
}(__1.Div));
exports.NumericRange = NumericRange;
