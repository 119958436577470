import { PlaylistViewer } from "./Components/PlaylistViewer";
import { Button, HashRef, Snack } from '@tblabs/truffle';
import { PlaylistSelector } from './Components/PlaylistSelector';
import { PlaylistPlayer } from './Services/PlaylistPlayer';
import { PlaylistPlayerView } from './Components/PlaylistPlayerView';
import { InteractWithDocumentFirst } from "./Services/InteractWithDocumentFirst";
import { Page } from './Pages/Page';
import { Playlist } from "./Models/Playlist";
import { Config } from './Services/Config';
import { Router } from './Services/Router';


export class PlayerPage extends Page
{
    private selector = new PlaylistSelector()
    private playlistName: string = "";
    private playlistPlayer = new PlaylistPlayer();

    public Init(playlist: string)
    {
        this.playlistName = playlist;

        return this;
    }
    
    protected async OnAppend(): Promise<void>
    {
        await this.selector.Init(this.playlistName);
    }

    protected async OnRemove(): Promise<void>
    {
        await this.playlistPlayer.Player.Stop()
    }

    constructor(private _router: Router, private _config: Config)
    {
        super();
     
        const listViewer = new PlaylistViewer()
            .OnSongClick(async (song) =>
            {
                await this.playlistPlayer.Play(song);
            });

        this.selector.OnPlaylistChange(async (playlist: Playlist) =>
        {
            // console.log(`OnPlaylistChange`, playlist.Name ? playlist.Name : "(no name)")

            this._router.SetPlaylist(playlist)
      
            await this.playlistPlayer.Load(playlist);

            if (this._config.Autostart)
            {
                await this.playlistPlayer.PlayAll();
            }

            listViewer.Load(playlist).Mark(this.playlistPlayer.CurrentSong);
        });

        this.playlistPlayer
            .OnSongChange(s =>
            {
                listViewer.Mark(s);
            })
            .OnPlaybackError(async (song, error) =>
            {
                if (error instanceof InteractWithDocumentFirst)
                {
                    new Snack("Click anywhere", "You need to interact with browser first in order to play audio", 0);
                    return;
                }

                new Snack("Playback problem", error.message, 15*1000);
                listViewer.MarkAsInvalid(song);
                //  await playlistPlayer.PlayNext();
            });


        this.top.Append(this.selector);
        this.middle.Append(listViewer);
        this.bottom.Append(new PlaylistPlayerView(this.playlistPlayer));
    }
}
