import { HandlersCollection, Ref } from "@tblabs/truffle";
import { Song } from "../Models/Song";
import { InteractWithDocumentFirst } from "./InteractWithDocumentFirst";

export class MusicPlayer
{
    private audio;
    private CurrentSong!: Song;
    private NextSong!: Song;
    public Duration = new Ref<number>(0);
    public CurrentTime = new Ref<number>(0);
    public StartAt = new Ref<number>(0);
    public Volume = new Ref<number>(100).Storable("volume");
    public IsPlaying = new Ref<boolean>(false);
    public IsWarmingUp = new Ref<boolean>(false);
    public IsLoaded = new Ref<boolean>(false);
    private playAfterStop = false;

    constructor()
    {
        this.audio = new Audio();
        this.audio.volume = (this.Volume.value || 100) / 100;
        this.audio.autoplay = false;
        this.audio.crossOrigin = 'anonymous';
        let allowCurrentTimeChange = false;

        this.audio.addEventListener("abort", (e) =>
        {
            console.log(`[Audio abort event]`)
        })

        this.audio.addEventListener("canplay", (e) =>
        {
            console.log(`[Audio canplay event]`)
            // this.IsPlaying.value = true;
        })

        this.audio.addEventListener("canplaythrough", (e) =>
        {
            console.log(`[Audio canplaythrough event]`)
        })

        this.audio.addEventListener("durationchange", (e) =>
        {
            // console.log(`[Audio durationchange event]`)
        })

        this.audio.addEventListener("emptied", (e) =>
        {
            console.log(`[Audio emptied event]`)
        })

        this.audio.addEventListener("ended", (e) =>
        {
            console.log(`[Audio ended event]`)
            this.IsPlaying.value = false;
            this.onSongEnd.Call(this.CurrentSong);
        })

        this.audio.addEventListener("loadeddata", (e) =>
        {
            console.log(`[Audio loadeddata event]`)
        })

        this.audio.addEventListener("loadedmetadata", (e) =>
        {
            console.log(`[Audio loadedmetadata event]`)
            console.log('Song duration:', this.audio.duration.toFixed(1), 'sec');
            this.Duration.value = this.audio.duration;
        })

        this.audio.addEventListener("pause", async (e) =>
        {
            console.log(`[Audio pause event]`)
            this.IsPlaying.value = false;

            if (this.playAfterStop)
                await this.Play(this.NextSong)
        })

        this.audio.addEventListener("play", (e) =>
        {
            console.log(`[Audio play event]`)
        })

        this.audio.addEventListener("playing", (e) =>
        {
            console.log(`[Audio playing event]`)
            this.IsPlaying.value = true;
        })

        this.audio.addEventListener("progress", (e) =>
        {
            // console.log(`[Audio progress event]`)
        })

        this.audio.addEventListener("ratechange", (e) =>
        {
            // console.log(`[Audio ratechange event]`)
        })

        this.audio.addEventListener("seeked", (e) =>
        {
            // console.log(`[Audio seeked event]`)
        })

        this.audio.addEventListener("seeking", (e) =>
        {
            // console.log(`[Audio seeking event]`)
        })

        this.audio.addEventListener("stalled", (e) =>
        {
            console.log(`[Audio stalled event]`, e)
            this.IsPlaying.value = false;

        })

        this.audio.addEventListener("suspend", (e) =>
        {
            console.log(`[Audio suspend event]`)
        })

        this.audio.addEventListener("timeupdate", (e) =>
        {
            // console.log(`[Audio timeupdate event]`)
            allowCurrentTimeChange = false;
            var currentTime = this.audio.currentTime;
            this.StartAt.value = currentTime;
            allowCurrentTimeChange = true;
        })

        this.audio.addEventListener("volumechange", (e) =>
        {
            // console.log(`[Audio volumechange event]`)
        })

        this.audio.addEventListener("waiting", (e) =>
        {
            // console.log(`[Audio waiting event]`)
        })

        this.audio.addEventListener("error", (e) =>
        {
            console.error(`[Audio error event]`, e)
            this.onSongError.Call(this.CurrentSong, e)
        });


        this.StartAt.OnChange(v =>
        {
            if (allowCurrentTimeChange)
            {
                this.audio.currentTime = v;
            }
        });

        this.Volume.OnChange(v =>
        {
            if (v >= 0 && v <= 100)
            {
                this.audio.volume = v / 100;
            }
        })
    }

    private atStartStartAt = 0;
    private firstPlay = false;
    public AtPlayStartAt(startAt)
    {
        this.atStartStartAt = startAt;
    }

    public async Play(song?: Song): Promise<void>
    {
        // console.log(`[Play] ${this.IsPlaying.value ? "PLAYING" : "STOPPED"}, NOW: `, { ...song }.Name)
        try
        {
            if (this.IsWarmingUp.value && song)
            {
                console.log(`🔥 Player is warming up. Please do not disturb`)

                return;
            }

            if (this.IsPlaying.value && this.CurrentSong?.Url == song?.Url)
            {
                console.log(`Already playing that`)
                return;
            }

            if (this.IsPlaying.value && song)
            {
                console.log(`Already playing but next song want to be played`)

                this.NextSong = song;
                await this.Stop()
                this.playAfterStop = true;

                return;
            }

            if (song)
            {
                this.IsWarmingUp.value = true;

                this.Load(song);
            }

            if (!this.CurrentSong)
            {
                console.log(`Nothing to play`)
                return;
            }

            if (this.IsPlaying.value)
            {
                console.log(`Already playing`)
                return;
            }

            console.log(`Trying to play "${this.CurrentSong.Name}"...`);

            await this.audio.play();

            if (this.atStartStartAt && this.firstPlay)
            {
                this.StartAt.value = this.atStartStartAt;
                this.firstPlay = false;
            }
        }
        catch (ex: any)
        {
            console.error('PLAY EX: ' + ex.message)

            if (ex.message.includes("Failed to load because no supported source was found"))
            {
                this.onSongError.Call(this.CurrentSong, new Error("Unsupported source"))
            }
            else if (ex.message.includes("failed because the user didn't interact with the document first"))
            {
                this.onSongError.Call(this.CurrentSong, new InteractWithDocumentFirst())
            }
            else 
            {
                this.onSongError.Call(this.CurrentSong, ex)
            }
        }
        finally
        {
            this.IsWarmingUp.value = false;
        }
    }

    public Load(song: Song): void
    {
        this.CurrentSong = song;
        this.firstPlay = true;

        this.audio.src = song.Url;
        this.audio.load();
    }

    public async Stop(): Promise<void>
    {
        this.firstPlay = false;
        console.log(`[STOP]`)
        // this.IsWarmingUp.value = false;

        if (!this.CurrentSong)
        {
            console.log(`Nothing to stop`)
            return;
        }

        if (this.IsPlaying.value == false)
        {
            console.log(`Already stopped`)
            return;
        }

        console.log(`Stopping "${this.CurrentSong.Name}"...`);

        this.playAfterStop = false;

        // await Delay(2000)
        this.audio.pause();
    }

    public async Toggle(song?: Song): Promise<void>
    {
        if (song && song?.Name != this.CurrentSong?.Name)
        {
            await this.Play(song)
            return;
        }

        if (this.IsPlaying.value)
        {
            await this.Stop()
        }
        else 
        {
            await this.Play()
        }
    }

    private onSongError = new HandlersCollection<(song, error) => void>();
    public OnSongError(handler: (song, error) => void): this
    {
        this.onSongError.Add(handler);

        return this;
    }

    private onSongEnd = new HandlersCollection<(song) => void>();
    public OnSongEnd(handler: (song) => void): this
    {
        this.onSongEnd.Add(handler);

        return this;
    }
}
