

export class List<T> {
    private currentIndex = 0;

    constructor(public Items: T[] = []) { }

    public Init(items: T[])
    {
        this.Items = items;
    }

    public SetCurrent(itemReference: T | undefined): void
    {
        if (!itemReference)
        {
            return;
        }

        this.currentIndex = this.Items.findIndex(x => x == itemReference);
    }

    public SetCurrentIndex(index: number): void
    {
        this.currentIndex = index;
    }

    public get CurrentIndex(): number
    {
        return this.currentIndex;
    }

    public get Current(): T
    {
        return this.Items[this.currentIndex];
    }

    public get Length(): number
    {
        return this.Items.length;
    }

    public First(): void
    {
        this.currentIndex = 0;
    }

    public Next(): void
    {
        this.currentIndex++;
        this.currentIndex %= this.Length;
    }

    public Previous(): void
    {
        this.currentIndex--;
        if (this.currentIndex < 0)
            this.currentIndex = this.Length - 1;
    }

    public Ranom(): void
    {
        if (this.Length <= 1)
        {
            return;
        }

        const min = 0;
        const max = this.Length - 1;

        const RandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

        let random = RandomNumber(min, max);

        let retries = 100;
        while (random == this.currentIndex && retries--)
        {
            random = RandomNumber(min, max);
        }

        this.currentIndex = random;
    }
}
