import 'babel-polyfill';
import { ModalWindow, Root, Button, Select, Range, DestroyingPatternContentSwitcher, HashRef, Span, Ref } from '@tblabs/truffle';
import { PlayerPage } from './PlayerPage';
import { Router } from './Services/Router';
import { Config } from './Services/Config';
import { PlaylistComposer } from './PlaylistComposer';
import { PlaylistProvider } from './Components/PlaylistSelector';

ModalWindow.Hook = Root.Hook;
ModalWindow.DefaultCssClasses = "tf-modal-window";
Button.DefaultCssClasses = "uk-button uk-button-default";
Select.DefaultCssClasses = "uk-select";
Range.DefaultCssClasses = "uk-range";

const root = new Root()

// root.Append(new PlaylistComposer(new PlaylistProvider()))
// return;
const hash = new HashRef();

const _router = new Router(hash)
const _config = new Config()

const pagesSwitcher = new DestroyingPatternContentSwitcher(hash)
    .AddContent("playlist/:playlist", ({ playlist }) => new PlayerPage(_router, _config).Init(playlist))
    .AddContent("playlist-composer", () => new PlaylistComposer(new PlaylistProvider()))
    .AddDefaultContent(() => new PlayerPage(_router, _config).Init("all"))

root.Append(pagesSwitcher)
