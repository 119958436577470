"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicPaginator = void 0;
var Label_1 = require("../../Controls/Inputs/Label");
var __1 = require("../..");
var PaginatorBrain_1 = require("./PaginatorBrain");
var BasicPaginator = /** @class */ (function (_super) {
    __extends(BasicPaginator, _super);
    function BasicPaginator(_start, _count, _total, _filtered) {
        var _this = _super.call(this) || this;
        _this._start = _start;
        _this._count = _count;
        _this._total = _total;
        _this._filtered = _filtered;
        _this.Brain = new PaginatorBrain_1.PaginatorBrain(_this._start, _this._count, _this._total, _this._filtered);
        _this.goToFirstButton = new __1.Button("«").FontSize(18).Padding(0, 14)
            .OnClick(function () { return _this.Brain.GoToFirst(); });
        _this.gotoPreviousButton = new __1.Button("‹").FontSize(18).Padding(0, 14)
            .OnClick(function () { return _this.Brain.GoToPreviousPage(); });
        _this.display = new Label_1.Label(_this.Brain.display).Style("vertical-align: middle;").Margin(8);
        _this.gotoNextButton = new __1.Button("›").FontSize(18).Padding(0, 14)
            .OnClick(function () { return _this.Brain.GoToNextPage(); });
        _this.gotoLastButton = new __1.Button("»").FontSize(18).Padding(0, 14)
            .OnClick(function () { return _this.Brain.GoToLast(); });
        return _this;
    }
    BasicPaginator.prototype.AddGoToFirstButton = function () {
        this.Append(this.goToFirstButton);
        return this;
    };
    BasicPaginator.prototype.AddGoToPreviousButton = function () {
        this.Append(this.gotoPreviousButton);
        return this;
    };
    BasicPaginator.prototype.AddDisplay = function () {
        this.Append(this.display);
        return this;
    };
    BasicPaginator.prototype.AddGoToNextButton = function () {
        this.Append(this.gotoNextButton);
        return this;
    };
    BasicPaginator.prototype.AddGoToLastButton = function () {
        this.Append(this.gotoLastButton);
        return this;
    };
    BasicPaginator.prototype.AddPagesCountSwitch = function (pageSizes) {
        if (pageSizes === void 0) { pageSizes = [5, 10, 20]; }
        var pageSizesOptions = pageSizes.map(function (x) { return new __1.SelectOption("".concat(x, " per page"), x); });
        var pagesCountSwitch = new __1.Select(this._count, pageSizesOptions).ModifyAfterChange(function (v) { return +v; }).Width(108);
        this.Append(pagesCountSwitch);
        return this;
    };
    return BasicPaginator;
}(__1.Div));
exports.BasicPaginator = BasicPaginator;
