import { HashRef } from '@tblabs/truffle';
import { Playlist } from '../Models/Playlist';


export class Router
{
    constructor(private _hash: HashRef) { }

    public SetPlaylist(playlist: Playlist): void
    {
        history.pushState(null, "", `#playlist/${playlist.Name}`);
    }
}
