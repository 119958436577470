import { Button, Div, Label } from '@tblabs/truffle';
import { VolumeControler } from './VolumeControler';
import { MusicPlayer } from '../Services/MusicPlayer';


export class VolumeSwitch extends Div
{
    constructor(player: MusicPlayer)
    {
        super();
        this.DisplayInlineBlock().HeightAuto().Background("none");

        this.Append(
            new Button("🔈").FontSize(32).Padding(0).Border(0, "").Width(32).DisplayInline()
                .OnClick(() => this.Append(new VolumeControler(player))),
            new Label(player.Volume).Color("#ccc").Width(32).DisplayInline()
        );
    }
}
