import { Button, Ref } from '@tblabs/truffle';
import { List } from "../Models/List";


export class PlaybackModeSelector extends Button
{
    private options = new List<string>(['🔁', '🔂', '🔀']);

    constructor(mode: Ref<number>)
    {
        super('🔁');
        this.FontSize(32).Border(0, "").Padding(0);

        this.options.SetCurrentIndex(mode.value);

        this.Label(this.options.Current);

        this.OnClick(() =>
        {
            this.options.Next();
            mode.value = this.options.CurrentIndex;

            this.Label(this.options.Current);
        });
    }
}
