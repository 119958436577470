"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnV2 = void 0;
var ColumnV2 = /** @class */ (function () {
    function ColumnV2(Headers, Mapping) {
        this.Headers = Headers;
        this.Mapping = Mapping;
    }
    return ColumnV2;
}());
exports.ColumnV2 = ColumnV2;
