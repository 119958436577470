
export class Song
{
    public Name = "";
    public Url = "";
    public Type = "";
    public IsBroken = false;

    constructor(url?)
    {
        if (url)
            this.Url = url;
    }
}
