import { Button, Checkbox, Div, NewLine, Range, Ref } from '@tblabs/truffle';
import { PlaylistPlayer } from '../Services/PlaylistPlayer';
import { PlaybackModeSelector } from './PlaybackModeSelector';
import { MusicPlayer } from '../Services/MusicPlayer';
import { VolumeSwitch } from './VolumeSwitch';

export class PlaylistPlayerView extends Div
{
    constructor(_playlistPlayer: PlaylistPlayer)
    {
        super();
        this.PositionRelative()

        const player = _playlistPlayer.Player;

        this.TextAlignCenter().PositionRelative().Append(
            new Range(player.StartAt).Max(player.Duration)
                .MarginBottom(32).WidthPercent(98),
            new NewLine(),
            new Button('⏪').FontSize(44).Border(0, "#222").Padding(0)
                .Disable(player.IsWarmingUp)
                .OnClick(async () => await _playlistPlayer.PlayPrevious()),
            new Button("Play").FontSize(52).Border(0, "#222").Padding(0)
                .Label(player.IsPlaying, v => v ? "⏸️" : "▶️")
                .Disable(player.IsWarmingUp)
                .OnClick(async () => await player.Toggle()),
            new Button('⏩').FontSize(44).Border(0, "#222").Padding(0)
                .Disable(player.IsWarmingUp)
                .OnClick(async () => await _playlistPlayer.PlayNext()),
            new PlaybackModeSelector(_playlistPlayer.Mode).FloatLeft(),
            new VolumeSwitch(player).FloatRight(),
        );
    }
}

export class MiniPlayerView extends Div
{
    constructor(player: MusicPlayer)
    {
        super();
        this.PositionFixed().Bottom(0).Background("#00374f")
            .Style(` display: flex; justify-content: space-between; align-items: center; width: 100%; flex-direction: row; height: auto; padding: 12px`)

        const add10sec = new Ref<boolean>(true)
            .OnChange(v => player.AtPlayStartAt(v ? 10 : 0), true)

        //display: block; height: 52px; padding: 8px; width: 100%;
        this.TextAlignCenter().PositionRelative().Append(
            new Checkbox(add10sec, "+10s").Style(`flex: 0 0 auto; padding: 0; margin-right: 8px`),
            new Button("Play").FontSize(32).Border(0, "#222")
                //.Padding(0).FloatLeft().WidthAuto()
                .Style(`flex: 0 0 auto; padding: 0; margin-right: 8px`)
                .Label(player.IsPlaying, v => v ? "⏸️" : "▶️")
                .Disable(player.IsWarmingUp)
                .OnClick(async () => await player.Toggle()),
            new Range(player.StartAt).Max(player.Duration)
                .Style(`flex: 1 1 auto; text-align: center;`),
            //.WidthPercent(80),
            new VolumeSwitch(player)//.FloatRight()
                .Style(`flex: 0 0 auto; text-align: right; margin-left: 8px`),
        );
    }
}
