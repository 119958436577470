import { Song } from "../Models/Song";
import { Playlist } from "../Models/Playlist";
import { MusicPlayer } from './MusicPlayer';
import { Ref } from '@tblabs/truffle';

export enum PlaybackMode 
{
    RepeatAll = 0,
    RepeatOne,
    Shuffle
}

export class PlaylistPlayer
{
    private playlist!: Playlist;
    public Player = new MusicPlayer();
    public Mode = new Ref<PlaybackMode>(PlaybackMode.RepeatAll).Storable("playback-mode");

    public get CurrentSong(): Song
    {
        return this.playlist.Current;
    }

    public async Play(song: Song): Promise<void>
    {
        const found = this.playlist.Items.find(s => s.Name == song.Name);

        this.playlist.SetCurrent(found);

        await this.Player.Play(this.playlist.Current);

        this.onSongChange(this.playlist.Current);
    }

    public async Load(playlist: Playlist): Promise<void>
    {
        this.playlist = playlist;

        this.StartFromFirstSong();

        this.Player.Load(playlist.Current)

        this.Player
            .OnSongEnd(async () =>
            {
                console.log(`--- SONG END. AUTO NEXT -------`)
                switch (this.Mode.value)
                {
                    case PlaybackMode.RepeatAll:
                        playlist.Next();
                        break;
                    case PlaybackMode.RepeatOne:
                        break;
                    case PlaybackMode.Shuffle:
                        playlist.Ranom();
                        break;
                }
                
                await this.Play(playlist.Current)
            })
            .OnSongError((song, error) =>
            {
                this.onPlaybackError?.(song, error)
            })
    }

    public async PlayAll(): Promise<void>
    {
        await this.Player.Play();
    }

    private StartFromFirstSong()
    {
        this.playlist.First();
    }

    private async PlayCurrent(): Promise<void>
    {
        const song = this.playlist.Current;

        await this.Player.Play(song);

        this.onSongChange(song);
    }

    public async PlayPrevious(): Promise<void>
    {
        console.log("--- PREV -----------------------------------------------")
        this.playlist.Previous();
        await this.PlayCurrent();
    }

    public async PlayNext(): Promise<void>
    {
        console.log("--- NEXT -----------------------------------------------")
        this.playlist.Next();
        await this.PlayCurrent();
    }

    private onPlaybackError!: (song: Song, error: Error) => void;
    public OnPlaybackError(handler: (song: Song, error: Error) => void)
    {
        this.onPlaybackError = handler;
        return this;
    }

    private onSongChange!: (song: Song) => void;
    public OnSongChange(handler: (song: Song) => void)
    {
        this.onSongChange = handler;
        return this;
    }
}

