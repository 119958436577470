import axios from 'axios';
import { Playlist } from "../Models/Playlist";
import { Select, Div, Ref, HandlersCollection, Label, Button } from '@tblabs/truffle';
import { NoPlaylistWindow } from './NoPlaylistWindow';

export class PlaylistProvider
{
    public async GetAllAvailableSongs()
    {
        console.log(`Downloading all songs...`)

        const req = await axios.get("https://music-database.specteam.pl/all");

        console.log(`Songs downloaded.`)

        return req.data;
    }
    public async EditPlaylistName(oldName: string, newName: string)
    {
        console.log(`Updating playlist name...`)

        const payload = {
            old: oldName,
            new: newName
        }
        await axios.post("https://music-database.specteam.pl/edit-playlist-name", payload)

        console.log(`Name update complete.`)
    }
    public async UpdatePlaylist(playlistName: string, songsNames: string[])
    {
        console.log(`Updating playlist...`)

        const payload = {
            name: playlistName,
            songs: songsNames
        }
        await axios.post("https://music-database.specteam.pl/update-playlist", payload)

        console.log(`Update complete.`)
    }

    public async GetAvailablePlaylistsNames(): Promise<string[]>
    {
        console.log(`Downloading playlists...`)

        const req = await axios.get("https://music-database.specteam.pl/playlists");

        console.log(`Playlists downloaded.`)

        return req.data;
    }
    
    public source = "https://music-database.specteam.pl";
    
    public async GetPlaylistSongs(playlistName: string): Promise<Playlist>
    {
        try
        {
            console.log(`Downloading playlist "${playlistName}"...`)

            const req = await axios.get(`${this.source}/playlist/${playlistName}`);
            const songsNamesWithExtensions = req.data;
            const playlist = new Playlist(playlistName, this.source + '/MUSIC')
                .LoadSongs(songsNamesWithExtensions);

            console.log(`Playlist downloaded.`)

            return playlist;
        }
        catch (ex)
        {
            console.log(`Downloading playlist failure!`)
            return new Playlist(playlistName, this.source)
        }
    }
}

export class PlaylistSelector extends Div
{
    private _playlistProvider = new PlaylistProvider();
    private selectedPlaylistName = new Ref<string>("").Storable("playlist");
    private select = new Select(this.selectedPlaylistName).Class("selector")

    constructor()
    {
        super("PlaylistSelector");

        this.selectedPlaylistName.OnChange(async (v) =>
        {
            // console.log(`Get songs for ${v}`)
            const playlist = await this._playlistProvider.GetPlaylistSongs(v);
// console.log(playlist)
            this.onPlaylistChange.Call(playlist);
        });

        this.Append(
            this.select,
            // new Button("📝⁝∷∴≣⋮").DisplayInlineBlock().WidthPercent(40).Padding(0).BorderNone().FontSize(32)
            new Button("⋮").Class("menu")
                .OnClick(()=>window.location.hash = "playlist-composer")
        );
    }

    public async Init(startWith: string): Promise<void>
    {
        const playlistFromAppRouter = startWith;
        const playlistFromAppLocalStorage = this.selectedPlaylistName.value;
        const availablePlaylistsNames = await this._playlistProvider.GetAvailablePlaylistsNames();
        let toPlay = "";

        if (playlistFromAppRouter)
        {
            const isAvailable = availablePlaylistsNames.includes(playlistFromAppRouter)

            if (isAvailable)
            {
                toPlay = playlistFromAppRouter;
            }
            else 
            {
                new NoPlaylistWindow(playlistFromAppRouter)
                toPlay = availablePlaylistsNames[0];
            }
        }
        else 
        {
            const isAvailable = availablePlaylistsNames.includes(playlistFromAppLocalStorage)

            if (isAvailable)
            {
                toPlay = playlistFromAppLocalStorage;
            }
            else 
            {
                new NoPlaylistWindow(playlistFromAppLocalStorage);
                toPlay = availablePlaylistsNames[0];
            }
        }


        this.select.LoadOptions(availablePlaylistsNames);

        if (toPlay)
        {
            console.log('playlist to play:', toPlay)
            // this.onPlaylistChange(toPlay);
            this.selectedPlaylistName.value = toPlay;
            const playlist = await this._playlistProvider.GetPlaylistSongs(toPlay);

            this.onPlaylistChange.Call(playlist);
            // this.onPlaylistChange.Call(toPlay);
        }
        else 
        {
            this.select.AddDefaultOption("Please select playlist")
        }
    }

    private onPlaylistChange = new HandlersCollection<(playlist: Playlist) => void>();
    public OnPlaylistChange(handler: (playlist: Playlist) => void)
    {
        this.onPlaylistChange.Add(handler)
        return this;
    }
}
