import { Div } from "@tblabs/truffle";
import { Song } from "../Models/Song";


export class PlaylistEntryView extends Div
{
    constructor(public song: Song)
    {
        super();
        this.CursorPointer()
            .MarginTop(2)
            .Color("#fff")
            .Style("white-space: nowrap")

        this.Append("🔵 " + song.Name);
    }

    public Selected()
    {
        this.Bold().Color("#4cc9ff")//.MarginLeft(8)
        return this;
    }

    public Unselected()
    {
        this.Normal().Color("#bdbdbd")//.MarginLeft(0)
        return this;
    }

    public Broken()
    {
        this.Color("red")
            .Clear()
            .Append("⚠️ BROKEN FILE ⚠️ " + this.song.Name)
            
        return this;
    }
}
