import { Button, Div, Label, Range, Span } from '@tblabs/truffle';
import { MusicPlayer } from '../Services/MusicPlayer';
import { MusicPlayer } from '../Services/MusicPlayer';



export class VolumeControler extends Div
{
    constructor(player: MusicPlayer)
    {
        super();
        this.PositionFixed().Bottom(0).Left(0)
            .WidthPercent(100).ZIndex(100)
            .Background("#222")
            .TextAlignCenter()
            .Padding(18)
            .PaddingBottom(248)

        this.Append(
            new Label(player.Volume, v => `Volume: ${v}%`).FontSize(24).Color("#f8f8f8").MarginTop(32),
            "\n",
            new Range(player.Volume, [0, 100]).WidthPercent(90).MarginTop(24),
            new Span("x").OnClick(() => this.RemoveSelf()).CursorPointer().FontSize(32).PositionAbsolute().Top().Right(16),
        );
    }
}
