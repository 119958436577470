"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginatorBrain = void 0;
var Ref_1 = require("../../Core/Ref");
var PaginatorBrain = /** @class */ (function () {
    function PaginatorBrain(start, count, total, filtered) {
        var _this = this;
        this.start = start;
        this.count = count;
        this.total = total;
        this.filtered = filtered;
        this.display = new Ref_1.Ref("");
        this.start.OnChange(function (_) { return _this.Refresh(); });
        this.count.OnChange(function (_) { return _this.Refresh(); });
        this.total.OnChange(function (_) { return _this.Refresh(); });
        this.filtered.OnChange(function (_) { return _this.Refresh(); });
        this.Refresh();
    }
    PaginatorBrain.prototype.Refresh = function () {
        var _a = this.Calc(), start = _a[0], end = _a[1];
        var total = this.total.value;
        var filtered = this.filtered.value;
        if (filtered > 0 && total > 0) {
            start += 1;
            if (start > end)
                start = end;
        }
        if (start < 0)
            start = 0;
        if (filtered != total)
            this.display.value = "".concat(start, " - ").concat(end, " / ").concat(filtered, " filtered / ").concat(total, " in total");
        else if (start == end && start == 0) {
            this.display.value = "No results";
        }
        else if (start == end) {
            this.display.value = "".concat(start, " / ").concat(total);
        }
        else
            this.display.value = "".concat(start, " - ").concat(end, " / ").concat(total);
    };
    PaginatorBrain.prototype.Calc = function () {
        var start = this.start.value;
        var count = this.count.value;
        var last = this.filtered.value;
        var end = 0;
        if (start < 0)
            start = 0;
        if (start >= last)
            start = last;
        end = start + count;
        if (end >= last)
            end = last;
        if (start == end && start > 0)
            start -= 1;
        this.start.value = start;
        return [start, end];
    };
    PaginatorBrain.prototype.GoToNextPage = function () {
        this.start.value += this.count.value;
    };
    PaginatorBrain.prototype.GoToPreviousPage = function () {
        this.start.value -= this.count.value;
    };
    PaginatorBrain.prototype.GoToFirst = function () {
        this.start.value = 0;
    };
    PaginatorBrain.prototype.GoToLast = function () {
        this.start.value = this.total.value - 1;
    };
    return PaginatorBrain;
}());
exports.PaginatorBrain = PaginatorBrain;
