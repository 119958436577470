import { Button, Checkbox, Collection, Div, HandlersCollection, Ref, Select, SelectOption, Snack, Span, TwinColumns, UnorderedList, Validator } from '@tblabs/truffle';
import { PlaylistProvider } from './Components/PlaylistSelector';
import { Song } from './Models/Song';
import { Playlist } from './Models/Playlist';
import { MiniPlayerView } from './Components/PlaylistPlayerView';
import { MusicPlayer } from './Services/MusicPlayer';

export class SongsListEntryV1 extends Div
{
    constructor(songs: Collection<Song>, song: Song, songIndex: number)
    {
        super()
        this.Class("list-item")

        const songName = new Span(song.Name).MarginLeft(4);
        this.Append(
            songName,
            new Span("▶️").MarginLeft(16).CursorPointer().OnClick(() => this.onPlayClick.Call(song)),
            new Span("🚮").CursorPointer().OnClick(() => songs.Remove(song)),
            songIndex > 0 && new Span("🔼").CursorPointer().OnClick(() => songs.Swap(songIndex, songIndex - 1)),
            songIndex < (songs.Count - 1) && new Span("🔽").CursorPointer().OnClick(() => songs.Swap(songIndex, songIndex + 1)),
            new Span("➡️").CursorPointer().OnClick(self =>
            {
                this.onExportClick.Call(song)
                self.Border(1, "lightblue", 4)
            }),
        )
    }

    private onExportClick = new HandlersCollection<(song: Song) => void>();
    public OnExportClick(handler: (song: Song) => void)
    {
        this.onExportClick.Add(handler)
        return this;
    }

    private onPlayClick = new HandlersCollection<(song: Song) => void>();
    public OnPlayClick(handler: (song: Song) => void)
    {
        this.onPlayClick.Add(handler)
        return this;
    }
}
export class SongsListEntry extends Div
{
    constructor({
        song,
        onPlayClick,
        onDeleteClick,
        onSwapUpClick,
        onSwapDownClick,
        onExportClick })
    {
        super()
        this.Class("list-item")

        const songName = new Span(song.Name).MarginLeft(4);
        this.Append(
            songName,
            new Span("▶️").MarginLeft(16).CursorPointer().OnClick(() => onPlayClick(song)),
            new Span("🚮").CursorPointer().OnClick(() => onDeleteClick(song)),
            onSwapUpClick && new Span("🔼").CursorPointer().OnClick(() => onSwapUpClick()),
            onSwapDownClick && new Span("🔽").CursorPointer().OnClick(() => onSwapDownClick()),
            onExportClick && new Span("➡️").CursorPointer().OnClick(self =>
            {
                onExportClick(song)
                self.Border(1, "lightblue", 4)
            }),
        )
    }
}

export class SongsList extends Div
{
    private songsCollection = new Collection<Song>()

    constructor(private exportable = true)
    {
        super("list")
    }

    public get Songs(): string[]
    {
        return this.songsCollection.Items.map(x => [x.Name, x.Type].join("."));
    }

    public Add(song: Song): void
    {
        if (this.songsCollection.Items.find(s => s.Name == song.Name))
        {
            return;
        }

        this.songsCollection.Add(song);
    }

    public LoadSongs(playlist: Playlist): void
    {
        let exportCallback = this.exportable
            ? (song) => this.onExport.Call(song)
            : null;

        this.songsCollection
            .Load(...playlist.Items)
            .ClearHandlers()
            .OnChange(songs =>
            {
                this.Clear()
                    .Append(
                        ...songs.map((song, songIndex) =>
                        {
                            const swapUpCallback = songIndex > 0
                                ? () => this.songsCollection.Swap(songIndex, songIndex - 1)
                                : null;

                            const swapDownCallback = songIndex < (this.songsCollection.Count - 1)
                                ? () => this.songsCollection.Swap(songIndex, songIndex + 1)
                                : null;

                            return new SongsListEntry({
                                song,
                                onPlayClick: song => this.onPlay.Call(song),
                                onDeleteClick: song => this.songsCollection.Remove(song),
                                onSwapUpClick: swapUpCallback,
                                onSwapDownClick: swapDownCallback,
                                onExportClick: exportCallback
                            })
                        })
                    )
            }, true)
    }

    private onExport = new HandlersCollection<(song: Song) => void>();
    public OnExport(handler: (song: Song) => void)
    {
        this.onExport.Add(handler)
        return this;
    }

    private onPlay = new HandlersCollection<(song: Song) => void>();
    public OnPlay(handler: (song: Song) => void)
    {
        this.onPlay.Add(handler)
        return this;
    }
}


export class PlaylistComposer extends TwinColumns
{
    private player = new MusicPlayer();

    constructor(private _playlistProvider: PlaylistProvider)
    {
        super();
        this.Class("PlaylistComposer")
    }

    protected async OnRemove(): Promise<void>
    {
        await this.player.Stop();
    }

    public async OnAppend()
    {
        const availablePlaylists = await this._playlistProvider.GetAvailablePlaylistsNames()

        const leftList = new SongsList()
            .OnExport(song => rightList.Add(song))
            .OnPlay(song => this.player.Toggle(song))
        const rightList = new SongsList(false)

        rightList.Add(new Song())

        const leftPlaylistName = new Ref<string>("").Storable("left-list");
        const leftSelect = new Select(leftPlaylistName).LoadOptions(availablePlaylists)
            .OnChange(async (playlistName) =>
            {
                const playlist = await this._playlistProvider.GetPlaylistSongs(playlistName);

                leftList.LoadSongs(playlist)
            }, true)

        const rightPlaylistName = new Ref<string>("").Storable("right-list");
        const rightSelect = new Select(rightPlaylistName).LoadOptions(availablePlaylists)
            .OnChange(async (playlistName) =>
            {
                const playlist = await this._playlistProvider.GetPlaylistSongs(playlistName);

                rightList.LoadSongs(playlist)
            }, true)

        this.LeftColumn.Append(
            new Button("All", async () =>
            {
                const allSongs = await this._playlistProvider.GetAllAvailableSongs()

                leftSelect.Value = "all";

                leftList.LoadSongs(new Playlist("all", this._playlistProvider.source).LoadSongs(allSongs))
            }),
            leftSelect.WidthAuto(),
            new Button("💾", async () =>
            {
                await this._playlistProvider.UpdatePlaylist(leftPlaylistName.value, leftList.Songs)

                new Snack("Saved")
            }),
            leftList,
        );

        this.RightColumn.Append(
            rightSelect.WidthAuto(),
            new Button("🍋", () =>
            {
                const name = window.prompt("Enter new list name:", "yet another list")
                if (name == null)
                {
                    return;
                }
                if (rightSelect.Options.map(x => x.Text).includes(name))
                {
                    alert("Name already taken!")
                    return;
                }
                rightSelect.AddOption(new SelectOption(name))
            }),
            new Button("📝", async () =>
            {
                const oldName = rightPlaylistName.value;
                const newName = window.prompt(`Change "${rightPlaylistName.value}" list name:`, rightPlaylistName.value)
                if (newName == null)
                {
                    return;
                }
                if (rightSelect.Options.map(x => x.Text).includes(newName))
                {
                    alert("Name already taken!")
                    return;
                }

                await this._playlistProvider.EditPlaylistName(oldName, newName)

                // const options = [...rightSelect.Options]
                // const foundIndex = options.findIndex(x => x.Value == oldName)
                // options[foundIndex].Text = newName;
                // options[foundIndex].Value = newName;
                // rightSelect.LoadOptions(options)
                rightSelect.ReplaceOption(x => x.Value == oldName, new SelectOption(newName))
                rightPlaylistName.value = newName;
            }),
            new Button("💾", async () =>
            {
                await this._playlistProvider.UpdatePlaylist(rightPlaylistName.value, rightList.Songs)

                new Snack("Saved")
            }),
            rightList,
        )

        this.Append(new MiniPlayerView(this.player))
    }
}
