"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Link = void 0;
var __1 = require("../..");
var Link = /** @class */ (function (_super) {
    __extends(Link, _super);
    function Link(label, url, openInNewCard) {
        var _this = _super.call(this, "a") || this;
        _this.Html(label);
        if (url === undefined && label.startsWith("http")) {
            url = label;
        }
        if (url)
            _this.Attribute("href", url);
        if (openInNewCard)
            _this.OpenInNewCard();
        return _this;
    }
    Link.prototype.OpenInNewCard = function () {
        this.Attribute("target", "_blank");
        return this;
    };
    Link.prototype.DoNotOpenInNewCard = function () {
        this.RemoveAttribute("target");
        return this;
    };
    Link.prototype.NoDecorationOnHover = function () {
        this.Style("text-decoration: none");
        return this;
    };
    Link.Name = "Link";
    return Link;
}(__1.ComponentBase));
exports.Link = Link;
