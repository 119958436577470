"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Range = void 0;
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var Ref_1 = require("../../Core/Ref");
var Range = /** @class */ (function (_super) {
    __extends(Range, _super);
    function Range(ref, range) {
        if (ref === void 0) { ref = new Ref_1.Ref(0); }
        if (range === void 0) { range = [0, 100]; }
        var _this = _super.call(this, "input", ref) || this;
        _this.min = undefined;
        _this.max = undefined;
        _this.Class(Range.DefaultCssClasses);
        _this.Min(range[0]);
        _this.Max(range[1]);
        _this.element.setAttribute("type", "range");
        _this.element.setAttribute("value", _this.ref.value.toString());
        _this.element.addEventListener('input', function (e) {
            _this.ref.value = +e.target.value;
        });
        return _this;
    }
    Range.prototype.Min = function (min) {
        if (min instanceof Ref_1.Ref) {
            this.MinRef(min);
            return this;
        }
        this.min = min;
        this.element.setAttribute("min", min.toString());
        return this;
    };
    Range.prototype.Max = function (max) {
        if (max instanceof Ref_1.Ref) {
            this.MaxRef(max);
            return this;
        }
        this.max = max;
        this.element.setAttribute("max", max.toString());
        return this;
    };
    Range.prototype.MinRef = function (ref) {
        var _this = this;
        ref.OnChange(function (v) {
            _this.min = v;
            _this.element.setAttribute("min", _this.min.toString());
        });
        this.min = ref.value;
        this.element.setAttribute("min", this.min.toString());
        return this;
    };
    Range.prototype.MaxRef = function (ref) {
        var _this = this;
        ref.OnChange(function (v) {
            _this.max = v;
            _this.element.setAttribute("max", _this.max.toString());
        });
        this.max = ref.value;
        this.element.setAttribute("max", this.max.toString());
        return this;
    };
    Range.prototype.Refresh = function () {
        this.element.value = this.ref.value.toString();
    };
    Range.Name = "Range";
    Range.DefaultCssClasses = "";
    return Range;
}(RefComponentBase_1.RefComponentBase));
exports.Range = Range;
