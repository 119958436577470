import { Div } from '@tblabs/truffle';

export class Page extends Div
{
    public top = new Div("top")
    public middle = new Div("middle")
    public bottom = new Div("bottom")

    constructor()
    {
        super("container");

        this.Append(
            this.top,
            this.middle,
            this.bottom)
    }
}
