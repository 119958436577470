"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var Ref_1 = require("../../Core/Ref");
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button(label, onClick) {
        if (label === void 0) { label = "Button"; }
        var _this = _super.call(this, "button") || this;
        _this.label = label;
        _this.Class(Button.DefaultCssClasses);
        if (onClick)
            _this.OnClick(onClick);
        _this.Label(_this.label);
        return _this;
    }
    Button.prototype.Label = function (label, transform) {
        var _this = this;
        if (label instanceof Ref_1.Ref) {
            label.OnChange(function (v) {
                if (transform) {
                    _this.element.innerHTML = transform(v);
                }
                else {
                    _this.element.innerHTML = v.toString();
                }
            }, true);
        }
        else {
            if (transform) {
                this.element.innerHTML = transform(label);
            }
            else {
                this.element.innerHTML = label.toString();
            }
        }
        return this;
    };
    Button.Name = "Button";
    Button.DefaultCssClasses = "";
    return Button;
}(ComponentBase_1.ComponentBase));
exports.Button = Button;
