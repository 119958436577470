import { ModalWindow, Button } from '@tblabs/truffle';


export class NoPlaylistWindow extends ModalWindow
{
    constructor(nonExistingPlaylist)
    {
        super("Playlist error");

        this.AddContent(`Playlist "${nonExistingPlaylist}" does not exist`);

        this.AddToFooter(
            new Button("OK", () =>
            {
                this.CloseWindow();
            })
        );

        this.OpenWindow();
    }
}
