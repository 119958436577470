"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateInput = void 0;
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var DateInput = /** @class */ (function (_super) {
    __extends(DateInput, _super);
    function DateInput(ref) {
        var _this = _super.call(this, "input", ref) || this;
        _this.Class("uk-input");
        _this.Attribute("type", "date");
        _this.element.addEventListener('change', function (e) {
            var _a;
            var v = e.target.value;
            var _b = v.split("-"), year = _b[0], month = _b[1], day = _b[2];
            var m = month - 1;
            if (m < 0)
                m = 11;
            var d = day;
            if (d < 0)
                d = 1;
            var date = new Date(year, m, d);
            _this.ref.value = date;
            (_a = _this.onChange) === null || _a === void 0 ? void 0 : _a.call(_this, date);
        });
        _this.Refresh();
        return _this;
    }
    DateInput.prototype.OnChange = function (callback) {
        this.onChange = callback;
        return this;
    };
    DateInput.prototype.Refresh = function () {
        this.element.value = this.FormatDate(this.ref.value);
        if (this.showThatChanged)
            this.ref.HasChanged
                ? this.element.classList.add("tf-value-changed")
                : this.element.classList.remove("tf-value-changed");
    };
    DateInput.prototype.PadTo2Digits = function (num) {
        return num.toString().padStart(2, '0');
    };
    DateInput.prototype.FormatDate = function (date) {
        return ([
            date.getFullYear(),
            this.PadTo2Digits(date.getMonth() + 1),
            this.PadTo2Digits(date.getDate()),
        ].join('-')
        //   +
        //   ' ' +
        //   [
        //     this.PadTo2Digits(date.getHours()),
        //     this.PadTo2Digits(date.getMinutes()),
        //     // padTo2Digits(date.getSeconds()),  
        //   ].join(':')
        );
    };
    DateInput.Name = "DateInput";
    return DateInput;
}(RefComponentBase_1.RefComponentBase));
exports.DateInput = DateInput;
