"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NonRefEditableLabel = exports.EditableLabel = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var Label_1 = require("./Label");
var TextInput_1 = require("./TextInput");
var TextLabel_1 = require("./TextLabel");
var EditableLabel = /** @class */ (function (_super) {
    __extends(EditableLabel, _super);
    function EditableLabel(ref) {
        var _this = _super.call(this, "div") || this;
        _this.ref = ref;
        _this.valueBeforeEdit = "";
        _this.label = new Label_1.Label(ref)
            .CursorPointer()
            .Show()
            .OnClick(function () {
            _this.valueBeforeEdit = ref.value;
            var labelWidth = getComputedStyle(_this.label.Element).width;
            var w = +labelWidth.replace("px", "");
            if (w < 100)
                w += 32;
            _this.label.Hide();
            _this.textInput
                .Show()
                .Width(w)
                .Padding(2, 6)
                .Focus();
        });
        _this.textInput = new TextInput_1.TextInput(ref);
        _this.textInput
            .OnBlur(function () { return _this.EndOfEdit(); })
            .OnEnterKeyPressed(function () { return _this.EndOfEdit(); })
            .Hide();
        _this.Append(_this.label);
        _this.Append(_this.textInput);
        return _this;
    }
    EditableLabel.prototype.LabelConfig = function (config) {
        config(this.label);
        return this;
    };
    EditableLabel.prototype.TextInputConfig = function (config) {
        config(this.textInput);
        return this;
    };
    EditableLabel.prototype.OnEditEnd = function (callback) {
        this.editEnd = callback;
        return this;
    };
    EditableLabel.prototype.EndOfEdit = function () {
        var _a;
        if (!this.textInput.IsVisible())
            return; // To prevent double call
        this.textInput.Hide();
        this.label.Show();
        (_a = this.editEnd) === null || _a === void 0 ? void 0 : _a.call(this, this.valueBeforeEdit, this.ref.value);
    };
    EditableLabel.prototype.DefaultText = function (text) {
        this.label.DefaultText(text);
        return this;
    };
    EditableLabel.prototype.Placeholder = function (placeholder) {
        this.textInput.Placeholder(placeholder);
        return this;
    };
    EditableLabel.prototype.Style = function (prop, val) {
        this.label.Style(prop, val);
        this.textInput.Style(prop, val);
        return this;
    };
    EditableLabel.Name = "EditableLabel";
    return EditableLabel;
}(ComponentBase_1.ComponentBase));
exports.EditableLabel = EditableLabel;
var NonRefEditableLabel = /** @class */ (function (_super) {
    __extends(NonRefEditableLabel, _super);
    function NonRefEditableLabel(value, onChange) {
        var _this = _super.call(this, "div") || this;
        _this.value = "";
        _this.label = new TextLabel_1.TextLabel(value);
        _this.label
            .Show()
            .OnClick(function () {
            var labelWidth = getComputedStyle(_this.label.Element).width;
            var w = +labelWidth.replace("px", "");
            if (w < 100)
                w += 20;
            _this.label.Hide();
            _this.textInput
                .Show()
                .Width(w)
                .Padding(2, 6)
                .Focus();
        });
        _this.textInput = new TextInput_1.NonRefTextInput(value, function (v) { _this.value = v; onChange(v); });
        _this.textInput
            .OnBlur(function () {
            _this.textInput.Hide();
            _this.label.Show();
            _this.label.Text(_this.value);
        })
            .OnEnterKeyPressed(function () {
            _this.textInput.Hide();
            _this.label.Show();
            _this.label.Text(_this.value);
        })
            .Hide();
        _this.Append(_this.label);
        _this.Append(_this.textInput);
        return _this;
    }
    NonRefEditableLabel.prototype.Placeholder = function (placeholder) {
        this.textInput.Placeholder(placeholder);
        return this;
    };
    NonRefEditableLabel.prototype.Style = function (prop, val) {
        this.label.Style(prop, val);
        this.textInput.Style(prop, val);
        return this;
    };
    return NonRefEditableLabel;
}(ComponentBase_1.ComponentBase));
exports.NonRefEditableLabel = NonRefEditableLabel;
